import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.div`
		position: relative;
	`;

	export const Content = styled.div`
		display: flex;
		gap: 24px;
	`;

	export const Details = styled.div`
		flex: 1;
	`;

	export const CandidateInfo = styled.div`
		background-color: ${COLORS.white};
		border-radius: 10px;
		box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
		margin-bottom: 16px;
		padding: 24px;
	`;

	export const CandidateFirstLine = styled.div`
		display: flex;
		justify-content: space-between;
		margin-bottom: 12px;
	`;

	export const CandidateInfoName = styled.h3`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 32px;
		letter-spacing: -0.264px;
	`;

	export const CandidateInfoViewProfileButtons = styled.div`
		display: flex;
		gap: 16px;
		align-items: center;
	`;

	export const CandidateInfoViewProfileButton = styled.div`
		button {
			background: ${COLORS.lightGray3};
			color: ${COLORS.darkGray2};
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: 12px;
			display: flex;
			align-items: center;
			padding: 12px 24px;
		}
	`;

	export const CandidateSecondLine = styled.div`
		display: flex;
		align-items: center;
		gap: 24px;
	`;

	export const CandidateInfoLocation = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		display: flex;
		align-items: center;
		gap: 8px;
	`;

	export const CandidateInfoPhone = styled.div`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		display: flex;
		align-items: center;
		gap: 8px;
	`;

	export const CandidateInfoEmail = styled.div`
		color: ${COLORS.blue};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		text-decoration-line: underline;
		display: flex;
		align-items: center;
		gap: 8px;
	`;

	export const CandidateThreads = styled.div``;

	export const CandidateThread = styled.div`
		padding: 20px 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: ${COLORS.white};
		box-shadow: 0px 2px 4px 0px rgba(99, 111, 122, 0.12);
		border-radius: 10px;
		margin-bottom: 16px;
	`;

	export const CandidateThreadPart = styled.div`
		display: flex;
		align-items: center;
	`;

	export const CandidateThreadTopic = styled.h4`
		color: ${COLORS.black};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		margin-right: 8px;
	`;

	export const CandidateThreadNumberOfMessages = styled.div`
		background-color: ${COLORS.lightGray2};
		padding-left: 8px;
		padding-right: 8px;
		color: ${COLORS.darkGray2};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%; /* 24px */
		letter-spacing: -0.176px;
		text-transform: capitalize;
		border-radius: 4px;
	`;

	export const CandidateThreadDate = styled.div`
		color: ${COLORS.black};
		text-align: right;
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 24px;
		margin-right: 24px;
	`;

	export const OpenThread = styled.div``;

	export const OpenThreadHeader = styled.div`
		background-color: ${COLORS.white};
		padding: 16px;
		border-radius: 10px 10px 0 0;
		border-bottom: 1px solid ${COLORS.lightGray1};
	`;

	export const OpenThreadTopic = styled.div`
		display: flex;
		align-items: center;
		gap: 16px;
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: -0.22px;
		cursor: pointer;
	`;

	export const NoSearchResults = styled.div`
		color: ${COLORS.darkGray2};
		text-align: center;
		font-size: 16px;
	`;
}
