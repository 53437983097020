import React from 'react';

import dayjs from 'dayjs';

import { ColumnsDataType } from 'components/Table/Table.types';
import { Routes } from 'types/routes';

import { Styled } from './GravityJobsTable.styled';
import { DataTableType } from './GravityJobsTable.types';

export const columns = (): ColumnsDataType => [
	{
		title: 'Job Title',
		dataIndex: 'title',
		key: 'title',
		render: (_: string, { title, jobLink }: Partial<DataTableType>) => <span>{title}</span>,
	},
	{
		title: 'Method',
		dataIndex: 'method',
		key: 'method',
		render: (_: string, { method }: Partial<DataTableType>) => <span>{method}</span>,
	},
	{
		title: 'Date Created',
		dataIndex: 'dateCreated',
		key: 'created',
		align: 'center',
		render: (_: string, { created }: Partial<DataTableType>) => (
			<span>{dayjs(created).format('MMM DD, YYYY')}</span>
		),
	},
	{
		title: 'Applied Candidates',
		dataIndex: 'appliedCandidates',
		key: 'appliedCandidates',
		align: 'center',
		render: (_: string, { id, applications }: Partial<DataTableType>) => {
			const isDisabled = !applications?.length;

			return (
				<Styled.Link
					to={isDisabled ? '#' : `${Routes.ATS}${Routes.GravityJob}/${id}`}
					isDisabled={isDisabled}
				>
					<span>{applications?.length || 0}</span>
				</Styled.Link>
			);
		},
	},
];
